import { AD_TYPE, Icon, Image, Video } from './globalTypes';

export enum AD_STATUS {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  ERROR = 'ERROR',
}

export enum LANGUAGE {
  NL = 'NL',
  FR = 'FR',
  DK = 'DK',
}

export enum COUNTRY {
  NL = 'NL',
  BE = 'BE',
  DK = 'DK',
}
export enum LEADGEN_FORM_TYPE {
  NAME_EMAIL = 'NAME_EMAIL',
  NAME_EMAIL_ANSWER = 'NAME_EMAIL_ANSWER',
  NAME_ADDRESS = 'NAME_ADDRESS',
  NAME_PHONE_NUMBER = 'NAME_PHONE_NUMBER',
  PHONE_NUMBER = 'PHONE_NUMBER',
  NAME_EMAIL_ADDRESS = 'NAME_EMAIL_ADDRESS',
  NAME_EMAIL_PHONE_NUMBER_POSTAL_CODE = 'NAME_EMAIL_PHONE_NUMBER_POSTAL_CODE',
}

export enum RECEIVE_DATA_DELIVERY_TYPE {
  EMAIL = 'EMAIL',
  HUBSPOT = 'HUBSPOT',
  ZAPIER = 'ZAPIER',
}

export interface Creative {
  advertiserCountry: COUNTRY;
  landingPage: string;
  name: string;
  language: LANGUAGE;
  mediaData: {
    headline?: string;
    body?: string;
    cta?: string;
    primaryText?: string;
    sponsoredBy?: string;
  };
  format: AD_TYPE;
  files: {
    name: string;
    fileUrl: string;
    relationType: 'IMAGE' | 'VIDEO' | 'ICON';
    vastTag?: string;
    videoPreviewFileUrl?: string;
  }[];
  leadgenForm?: {
    bodyText: string;
    formType: LEADGEN_FORM_TYPE;
    dataDeliveryType?: RECEIVE_DATA_DELIVERY_TYPE;
    email?: string;
    privacyStatementUrl?: string;
    headerImageMediaFile: {
      fileUrl: string;
    };
  };
}
